import { Button, Container, HStack, useBoolean } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import { getShortenedAddress } from "../utils/walletUtils";
import Logo from "./Logo";
import Panel from "./Panel";
import WalletPopup from "./WalletPopup";

const Header: React.FC = () => {
  const { address } = useAccount();
  const [isWalletDialogVisible, setIsWalletDialogVisible] = useBoolean();

  return (
    <>
      <Panel as="header" px="0" py="4" borderRadius="0">
        <Container>
          <HStack overflow="hidden" spacing="4">
            <Link to="/">
              <Logo w="14em" />
            </Link>

            <HStack justify="flex-end" w="full" spacing="3">
              {address && (
                <Button
                  flex="none"
                  color="bright.50"
                  borderColor="dark.500"
                  _hover={{
                    borderColor: "primary.500",
                  }}
                  colorScheme="bright"
                  borderRadius="full"
                  variant="outline"
                  size="sm"
                  onClick={setIsWalletDialogVisible.on}
                >
                  {getShortenedAddress(address, 3)}
                </Button>
              )}
            </HStack>
          </HStack>
        </Container>
      </Panel>

      {isWalletDialogVisible && (
        <WalletPopup isOpen onClose={setIsWalletDialogVisible.off} />
      )}
    </>
  );
};

export default Header;
