import { Box, Flex, FlexProps, Heading, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FaTimes } from "react-icons/fa";
import IconCover from "./IconCover";

type ErrorDetailsProps = {
  icon?: React.ReactNode;
  title: string;
  description?: string;
} & FlexProps;

const ErrorDetails: React.FC<ErrorDetailsProps> = ({
  icon,
  title,
  description,
  children,
  ...rest
}) => (
  <Flex flexDir="column" align="center" textAlign="center" {...rest}>
    <IconCover>{icon || <Icon as={FaTimes} w="8" h="8" />}</IconCover>

    <Heading mt="6" fontWeight="800" fontSize="xl">
      {title}
    </Heading>

    {description && (
      <Text mt="3" lineHeight="tall" opacity="0.6">
        {description}
      </Text>
    )}

    {children ? (
      <Box w="full" mt={6}>
        {children}
      </Box>
    ) : null}
  </Flex>
);

export default ErrorDetails;
