import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";
import { BrowserTracing } from "@sentry/tracing";
import config from "./constants/config";

if (config.sentry.sentryDsn) {
  Sentry.init({
    dsn: config.sentry.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: config.environment,
    maxValueLength: 1000,
  });
}

if (config.ga.gaId) {
  ReactGA.initialize(config.ga.gaId);
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
