import { BigNumber } from "ethers";
import React from "react";

export type WalletAssetsContextValue = {
  balance?: BigNumber;
  nfts?: number[];
  isLoading: boolean;
};

// @ts-ignore
const WalletAssetsContext = React.createContext<WalletAssetsContextValue>();

WalletAssetsContext.displayName = "WalletAssetsContext";

export default WalletAssetsContext;
