import { FunctionComponent, useMemo } from "react";
import { useAccount, useBalance } from "wagmi";
import WalletAssetsContext, {
  WalletAssetsContextValue,
} from "../contexts/WalletAssetsContext";
import useTriluxInWallet from "../hooks/useTriluxInWallet";

const WalletAssetsProvider: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const { address } = useAccount();

  const nfts = useTriluxInWallet(address);

  const { data: balance } = useBalance({
    addressOrName: address,
  });

  const contextValue = useMemo<WalletAssetsContextValue>(
    () => ({
      balance: balance?.value,
      nfts,
      isLoading: nfts === undefined || balance === undefined,
    }),
    [balance, nfts]
  );

  return (
    <WalletAssetsContext.Provider value={contextValue}>
      {children}
    </WalletAssetsContext.Provider>
  );
};

export default WalletAssetsProvider;
