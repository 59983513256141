import { Box, Button, Center, Container, Icon, VStack } from "@chakra-ui/react";
import { FaArrowDown } from "react-icons/fa";
import BoldSpinner from "../components/BoldSpinner";
import EmptyState from "../components/EmptyState";
import config from "../constants/config";
import useTranslate from "../hooks/useTranslate";
import useWalletAssets from "../hooks/useWalletAssets";

const ConnectedPage = () => {
  const translate = useTranslate();
  const { nfts, isLoading } = useWalletAssets();

  return (
    <Center flex="1">
      <Container maxW="lg">
        <VStack spacing="8" pt="16" pb="16" w="full">
          {isLoading ? (
            <Center h="sm">
              <BoldSpinner size="xl" />
            </Center>
          ) : nfts?.length ? (
            <VStack spacing="6">
              <video src={`${config.urls.publicUrl}/video.mp4`} autoPlay loop />

              <Button
                as="a"
                href={`${config.urls.publicUrl}/video.mp4`}
                download="trilux-nft"
                variant="outline"
                size="sm"
                borderRadius="full"
                leftIcon={<Icon as={FaArrowDown} />}
              >
                {translate("common:download")}
              </Button>
            </VStack>
          ) : (
            <Box p="6" bg="dark.700">
              <EmptyState label={translate("nft:empty")} />
            </Box>
          )}
        </VStack>
      </Container>
    </Center>
  );
};

export default ConnectedPage;
