import { useContractRead } from "wagmi";
import config from "../constants/config";

const useTriluxInWallet = (address?: string) => {
  const { data } = useContractRead({
    addressOrName: config.contracts.triluxContract.address,
    contractInterface: config.contracts.triluxContract.interface,
    functionName: "tokensInWallet",
    args: address,
    keepPreviousData: false,
  });

  return data as any as number[] | undefined;
};

export default useTriluxInWallet;
