import { SystemStyleFunction } from "@chakra-ui/react";
import { hexToRGB } from "../utils/colorUtils";

type StyleProps = Parameters<SystemStyleFunction>[0];

const theme = {
  styles: {
    global: {
      body: {
        overflowX: "hidden",
        backgroundColor: "dark.900",
        fontWeight: "500",
        color: "bright.50",
      },
      "html, body, #root": {
        height: "full",
        fontSize: {
          base: "14px",
          sm: "16px",
        },
      },
      "*:focus": {
        boxShadow: "none !important",
      },
      "*": {
        webkitTapHighlightColor: "rgba(255, 255, 255, 0)",
      },
    },
  },
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  colors: {
    primary: {
      50: "#cf0000",
      100: "#cf0000",
      200: "#cf0000",
      300: "#cf0000",
      400: "#cf0000",
      500: "#cf0000",
      600: "#cf0000",
      700: "#cf0000",
      800: "#cf0000",
      900: "#cf0000",
    },
    dark: {
      50: "#555",
      100: "#333",
      200: "#333",
      300: "#222",
      400: "#222",
      500: "#222",
      600: "#1F1F1F",
      700: "#111",
      800: "#111",
      900: "#000",
    },
    bright: {
      50: "#FFF",
      100: "#FFF",
      200: "#FFF",
      300: "#FFF",
      400: "#FFF",
      500: "#FFF",
      600: "#FFF",
      700: "#FFF",
      800: "#FFF",
      900: "#FFF",
    },
  },
  radii: {
    sm: "0",
    md: "0",
    lg: "0",
  },
  components: {
    Container: {
      baseStyle: {
        maxW: "container.sm",
        px: "4",
      },
    },
    Progress: {
      baseStyle: {
        track: {
          bg: hexToRGB("#FFFFFF", 0.25),
          borderRadius: "full",
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "lg",
        textDecoration: "none",
        boxSizing: "bordered-box",
        borderWidth: "0",
      },
      variants: {
        solid: { bg: "dark.500", _hover: { bg: "primary.500" } },
        outline: {
          border: "2px solid",
          color: "bright.50",
          borderColor: "dark.500",
          _active: {
            color: "bright.50",
            bg: "transparent",
            borderColor: "primary.500",
          },
          _hover: {
            color: "bright.50",
            bg: "transparent",
            borderColor: "primary.500",
          },
          _disabled: {
            color: "bright.50",
            borderColor: "dark.500",
            pointerEvents: "none",
          },
        },
        ghost: {
          _hover: {
            bg: "dark.800",
          },
          _active: {
            bg: "dark.800",
          },
        },
        link: {
          color: "bright.50",
          _active: {
            color: "primary.500",
          },
          _hover: {
            color: "primary.500",
            textDecoration: "none",
          },
          _disabled: {
            color: "bright.50",
            pointerEvents: "none",
          },
        },
      },
      sizes: {
        xs: {
          px: 3,
          height: 8,
          minW: 8,
        },
        sm: {
          px: 4,
          height: 10,
          minW: 10,
        },
        md: {
          px: 8,
          height: 12,
          minW: 12,
        },
        lg: {
          h: 14,
          px: 6,
          minW: 12,
          fontSize: "md",
        },
      },
      defaultProps: {
        colorScheme: "primary",
      },
    },
    CloseButton: {
      baseStyle: {
        borderRadius: "full",
        _hover: {
          bg: "dark.600",
        },
      },
    },
    Badge: {
      baseStyle: {
        fontWeight: "semibold",
        borderRadius: "full",
      },
    },
    Input: {
      sizes: {
        sm: {
          field: {
            borderRadius: "lg",
          },
        },
        md: {
          field: {
            borderRadius: "lg",
          },
        },
        lg: {
          field: {
            px: 4,
            borderRadius: "lg",
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: "dark.500",
            border: "2px solid",
            _placeholder: {
              color: "dark.100",
              opacity: 0.5,
              fontFamily: "inherit",
            },
            _hover: {
              borderColor: "dark.500",
            },
            _focus: {
              borderColor: "primary.500",
              boxShadow: "none",
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: "primary.500",
        errorBorderColor: "primary.500",
      },
    },
    InputRightAddon: {
      baseStyle: {
        bg: "transparent",
      },
    },
    Link: {
      baseStyle: {
        color: "dark",
        _hover: {
          color: "primary.500",
          textDecoration: "none",
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          boxShadow: "none",
          bg: "transparent",
          borderRadius: "none",
        },
        body: {
          py: 6,
          px: {
            base: 4,
            sm: 6,
          },
          borderRadius: "lg",
          bg: "dark.900",
          color: "bright.50",
        },
        closeButton: {
          top: 5,
          right: 5,
        },
        overlay: {
          bg: hexToRGB("#1b1b1b", 0.75),
        },
      },
      defaultProps: {
        isCentered: true,
      },
    },
    Divider: {
      baseStyle: {
        borderColor: "dark.500",
        borderBottomWidth: "2px",
      },
    },
    Tooltip: {
      baseStyle: {
        bg: "dark.500",
        color: "bright.50",
        borderRadius: "md",
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "sm",
        fontWeight: "semibold",
        mb: "1",
        ml: "1.5",
      },
    },
  },
};

export default theme;
