import { Button, ModalProps } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import CopyInput from "../components/CopyInput";
import SimplePopup from "../components/SimplePopup";
import useTranslate from "../hooks/useTranslate";

type WalletPopupProps = Omit<ModalProps, "children">;

const WalletPopup = (props: WalletPopupProps) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const handleDisconnect = () => {
    disconnect();
    navigate("/");
  };

  return (
    <SimplePopup size="sm" title={translate("walletPopup:title")} {...props}>
      <CopyInput value={address || ""} mt="6" mb="6" />

      <Button
        onClick={handleDisconnect}
        variant="solid"
        colorScheme="dark"
        w="full"
      >
        {translate("common:disconnect")}
      </Button>
    </SimplePopup>
  );
};

export default WalletPopup;
