import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightElement,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { FaRegCopy } from "react-icons/fa";
import useSnackbar from "../hooks/useSnackbar";
import useTranslate from "../hooks/useTranslate";

type CopyInputProps = InputGroupProps & {
  value: string;
  size?: InputProps["size"];
};

const CopyInput: React.FC<CopyInputProps> = ({
  value,
  size = "lg",
  ...rest
}) => {
  const translate = useTranslate();
  const label = translate("common:copy");
  const { onCopy: copy } = useClipboard(value);
  const snackbar = useSnackbar();

  const handleCopy = useCallback(() => {
    copy();
    snackbar("success", translate("common:copied"));
  }, [copy, snackbar, translate]);

  return (
    <InputGroup {...rest}>
      <Input
        size={size}
        value={value}
        isDisabled
        cursor="text !important"
        opacity="1 !important"
      />

      <InputRightElement h="full">
        <Tooltip label={label} placement="top">
          <IconButton
            minW="8"
            maxW="8"
            maxH="8"
            aria-label={label}
            lineHeight="none"
            icon={
              <Icon
                as={FaRegCopy}
                w={["sm", "md"].includes(size as string) ? "4" : "5"}
                h="auto"
              />
            }
            variant="unstyled"
            onClick={handleCopy}
            _hover={{ color: "primary.500" }}
          />
        </Tooltip>
      </InputRightElement>
    </InputGroup>
  );
};

export default CopyInput;
