/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import useTranslate from "../hooks/useTranslate";
import useSnackbar from "../hooks/useSnackbar";
// @ts-ignore
import { serializeError } from "eth-rpc-errors";

const useSnackbarError = (error?: any) => {
  const translate = useTranslate();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar(
        "error",
        serializeError(error).message || translate("error:default")
      );
    }
  }, [error]);
};

export default useSnackbarError;
