import { Center } from "@chakra-ui/react";
import { useAccount, useConnect } from "wagmi";
import BoldSpinner from "../components/BoldSpinner";

type AccountGuardProps = {
  yeyElement: JSX.Element;
  neyElement: JSX.Element;
};

const AccountGuard = ({ yeyElement, neyElement }: AccountGuardProps) => {
  const { isLoading } = useConnect();
  const { address, isConnected } = useAccount();

  if (isLoading) {
    return (
      <Center h="100vh">
        <BoldSpinner size="xl" />
      </Center>
    );
  }

  return isConnected && address ? yeyElement : neyElement;
};

export default AccountGuard;
