import {
  Button,
  Container,
  Divider,
  HStack,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import config from "../constants/config";
import useTranslate from "../hooks/useTranslate";
import Panel from "./Panel";
import TwitterIcon from "./TwitterIcon";

const Footer: React.FC = () => {
  const translate = useTranslate();

  return (
    <>
      <Panel as="footer" mt="auto" bg="dark.900" px="0" borderRadius="none">
        <Container>
          <HStack justify="space-between">
            <HStack spacing="5">
              <Link
                href={config.urls.facebookUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={FaFacebook} w="5" h="auto" />
              </Link>

              <Link
                href={config.urls.twitterUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={TwitterIcon} w="5" h="auto" />
              </Link>

              <Link
                href={config.urls.linkedinUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={FaLinkedin} w="5" h="auto" />
              </Link>

              <Link
                href={config.urls.instagramUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={FaInstagram} w="5" h="auto" />
              </Link>

              <Link
                href={config.urls.youtubeUrl}
                target="_blank"
                display="flex"
                alignItems="center"
              >
                <Icon as={FaYoutube} w="5" h="auto" />
              </Link>
            </HStack>

            <HStack justify="space-between" spacing="4">
              <Link href={config.urls.learnMoreUrl} target="_blank">
                <Button as="span" variant="link" fontSize="sm">
                  {translate("footer:learnMore")}
                </Button>
              </Link>

              <Link
                href={`${
                  config.chains![0].blockExplorers?.default.url
                }/address/${config.contracts.triluxContract.address}`}
                target="_blank"
              >
                <Button as="span" variant="link" fontSize="sm">
                  {translate("footer:contract")}
                </Button>
              </Link>
            </HStack>
          </HStack>

          <Divider my="3" />

          <Text textAlign="center" fontSize="xs" opacity="0.6">
            {translate("footer:copy")}
          </Text>
        </Container>
      </Panel>
    </>
  );
};

export default Footer;
