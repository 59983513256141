import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from "@chakra-ui/react";

export type SimplePopupProps = ModalProps & {
  title?: string;
  description?: string;
  isClosable?: boolean;
  hasScrollArea?: boolean;
};

const SimplePopup = ({
  title,
  description,
  size,
  isClosable = true,
  hasScrollArea = false,
  children,
  scrollBehavior,
  ...rest
}: SimplePopupProps) => {
  return (
    <Modal
      closeOnEsc={isClosable}
      closeOnOverlayClick={isClosable}
      scrollBehavior={scrollBehavior}
      {...rest}
    >
      <ModalOverlay />

      <ModalContent
        w={size}
        maxH={hasScrollArea || scrollBehavior === "inside" ? "100vh" : "100%"}
        bg="transparent"
        mx="0"
        my="0"
        px="3"
        py="3"
      >
        {isClosable && <ModalCloseButton />}

        <ModalBody>
          {(title || description) && (
            <VStack w="full" spacing="1" textAlign="center">
              {title && (
                <Heading
                  fontSize="2xl"
                  lineHeight="1.3"
                  fontWeight="bold"
                  maxW="18rem"
                  pt="2"
                >
                  {title}
                </Heading>
              )}

              {description && (
                <Text fontSize="sm" opacity="0.8" maxW="18rem">
                  {description}
                </Text>
              )}
            </VStack>
          )}

          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SimplePopup;
