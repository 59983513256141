import { chain } from "wagmi";
import triluxABI from "../abis/trilux.abi.json";

const isTestnet = parseInt(process.env.REACT_APP_USE_TESTNET!, 10) === 1;

const config = {
  environment: process.env.NODE_ENV as "production" | "development",
  isTestnet,

  chains: isTestnet ? [chain.polygonMumbai] : [chain.polygon],

  sentry: {
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  },

  ga: {
    gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
  },

  contracts: {
    triluxContract: {
      address: process.env.REACT_APP_TRILUX_CONTRACT_ADDRESS!,
      interface: triluxABI,
    },
  },

  urls: {
    publicUrl: process.env.PUBLIC_URL!,
    learnMoreUrl: "https://www.trilux.com/",
    twitterUrl: "https://twitter.com/triluxglobal",
    facebookUrl: "https://www.facebook.com/TRILUXDeutschland",
    instagramUrl: "https://www.instagram.com/trilux_deutschland/",
    linkedinUrl: "https://www.linkedin.com/company/trilux/",
    youtubeUrl: "https://www.youtube.com/c/TRILUX",
  },
};

export default config;
