// @ts-ignore
import { serializeError } from "eth-rpc-errors";
// @ts-ignore
import { SerializedEthereumRpcError } from "eth-rpc-errors/dist/classes";
import { useMemo } from "react";
import useSnackbarError from "../hooks/useSnackbarError";

const useSnackbarRpcError = (error?: any) => {
  const rpcError = useMemo(() => {
    if (!error) {
      return;
    }

    const serializedError: SerializedEthereumRpcError = serializeError(error);
    const errorData = serializedError.data as any;
    const originalError = errorData?.originalError;

    return {
      message:
        originalError?.message ||
        originalError?.error?.message ||
        originalError?.reason ||
        errorData?.message ||
        serializedError.message,
    };
  }, [error]);

  return useSnackbarError(rpcError);
};

export default useSnackbarRpcError;
