const translations = {
  "common:copy": "Copy",
  "common:copied": "Copied to clipboard!",
  "common:disconnect": "Disconnect",
  "common:free": "Free",
  "common:claim": "Claim",
  "common:buy": "Buy",
  "common:refresh": "Refresh",
  "common:cancel": "Cancel",
  "common:noResults": "No results…",
  "common:download": "Download",

  "error:default": "Oops! Something went wrong.",

  "success:default": "Success! Thank you.",

  "connect:title": "Connect Your Wallet",
  "connect:button:label": "Connect with {connector}",

  "footer:learnMore": "About",
  "footer:contract": "Contract",
  "footer:copy": "© 2022 TRILUX GmbH & Co. KG",

  "walletPopup:title": "Your Wallet",

  "unsupportedNetwork:title": "Wrong Network",
  "unsupportedNetwork:description":
    "Your wallet is connected to an unsupported network. Please change it to: {chainName}",
  "unsupportedNetwork:switch": "Switch Network",

  "nft:empty": "You don't have any Trilux NFT in your wallet",
};

export default translations;
