import { Button } from "@chakra-ui/react";
import { useSwitchNetwork } from "wagmi";
import config from "../constants/config";

import useTranslate from "../hooks/useTranslate";
import ErrorPopup, { ErrorPopupProps } from "../components/ErrorPopup";

type UnsupportedNetworkPopupProps = Omit<
  ErrorPopupProps,
  "title" | "description" | "children"
>;

const UnsupportedNetworkPopup = (props: UnsupportedNetworkPopupProps) => {
  const translate = useTranslate();
  const { switchNetwork } = useSwitchNetwork();

  return (
    <ErrorPopup
      title={translate("unsupportedNetwork:title")}
      description={translate("unsupportedNetwork:description", {
        chainName: config.chains[0].name,
      })}
      {...props}
    >
      {switchNetwork && (
        <Button
          onClick={() => {
            switchNetwork(config.chains[0].id);
          }}
          w="full"
          colorScheme="primary"
        >
          {translate("unsupportedNetwork:switch", {
            chainName: config.chains[0].name,
          })}
        </Button>
      )}
    </ErrorPopup>
  );
};

export default UnsupportedNetworkPopup;
