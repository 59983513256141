import { Button, Container, Heading, Image, VStack } from "@chakra-ui/react";
import { useConnect } from "wagmi";
import Panel from "../components/Panel";
import config from "../constants/config";
import usePageTracking from "../hooks/usePageTracking";
import useSnackbarRpcError from "../hooks/useSnackbarRpcError";
import useTranslate from "../hooks/useTranslate";

const ConnectPage = () => {
  const translate = useTranslate();
  const { connectors, connect, error } = useConnect();

  usePageTracking();
  useSnackbarRpcError(error);

  return (
    <>
      <Container
        display="flex"
        pt="12"
        pb="12"
        maxW="md"
        flex="1"
        alignItems="center"
        h="full"
      >
        <VStack w="full" align="center" spacing="12">
          <Image
            src={`${config.urls.publicUrl}/connect-logo.png`}
            w="270px"
            h="auto"
          />

          <Panel p="8" bg="dark.800">
            <Heading fontSize="xl" w="full" textAlign="center" mb="6" mt="1">
              {translate("connect:title")}
            </Heading>

            <VStack w="full" align="stretch" spacing="3">
              {connectors.map((connector) => (
                <Button
                  key={connector.id}
                  onClick={() => connect({ connector })}
                  isDisabled={!connector.ready}
                  size="lg"
                  w="full"
                >
                  {translate("connect:button:label", {
                    connector:
                      connector.name === "Injected"
                        ? "MetaMask"
                        : connector.name,
                  })}
                </Button>
              ))}
            </VStack>
          </Panel>
        </VStack>
      </Container>
    </>
  );
};

export default ConnectPage;
