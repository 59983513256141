import { Box } from "@chakra-ui/react";
import React from "react";
import Footer from "./Footer";

type LayoutProps = { children: JSX.Element | JSX.Element[] };

const Layout = ({ children }: LayoutProps) => (
  <Box
    minH="full"
    display="flex"
    flexDirection="column"
    justifyContent="stretch"
  >
    {children}
    <Footer />
  </Box>
);

export default Layout;
